button,
[type='button'],
[type='reset'],
[type='submit'] {
  background-image: unset;
}

@layer components {
  .btn {
    @apply relative flex items-center justify-center justify-items-center rounded-lg border py-2 px-6 font-semibold duration-200;
  }
}

@layer base {
  *:focus-visible {
    @apply outline-none ring-2 ring-copper/80 ring-offset-2 ring-offset-white/10 duration-100;
  }

  a {
    border-radius: 0.25rem;
  }
}
