@layer components {
  .photon-display {
    @apply text-[4rem] leading-[4.25rem] -tracking-[0.1875rem];
  }

  .photon-heading-xl {
    @apply text-[2.5625rem] leading-[3.375rem] -tracking-[0.125rem];
  }

  .photon-heading-lg {
    @apply text-[2.5rem] leading-[2.875rem] -tracking-[0.125rem];
  }

  .photon-heading-md {
    @apply text-[2.25rem] leading-[2.5rem] -tracking-[0.0625rem];
  }

  .photon-heading-sm {
    @apply text-[2rem] leading-[2.25rem] -tracking-[0.0625rem];
  }

  .photon-heading-xs {
    @apply text-[1.75rem] leading-[2rem] -tracking-[0.03125rem];
  }

  .photon-title-lg {
    @apply text-[1.5rem] leading-[1.625rem] -tracking-[0.03125rem];
  }

  .photon-title-md {
    @apply text-[1.25rem] leading-[1.5rem] tracking-normal;
  }

  .photon-title-sm {
    @apply text-[1.125rem] leading-[1.375rem] tracking-normal;
  }

  .photon-title-xs {
    @apply text-[1rem] leading-[1.25rem] tracking-normal;
  }

  .photon-title-xxs {
    @apply text-[0.875rem] leading-[1.125rem] tracking-normal;
  }

  .photon-eyebrow-md {
    @apply text-[0.8125rem] uppercase leading-[1rem] tracking-[0.0625rem];
  }

  .photon-eyebrow-sm {
    @apply text-[0.6875rem] uppercase leading-[0.875rem] tracking-[0.0625rem];
  }

  .photon-eyebrow-xs {
    @apply text-[0.563rem] uppercase leading-[0.813rem] tracking-[0.063rem];
  }

  .photon-paragraph-xl {
    @apply text-[1.25rem] leading-[2rem] tracking-normal;
  }

  .photon-paragraph-lg {
    @apply text-[1.125rem] leading-[1.875rem] tracking-normal;
  }

  .photon-paragraph-md {
    @apply text-[1rem] leading-[1.75rem] tracking-normal;
  }

  .photon-paragraph-sm {
    @apply text-[0.875rem] leading-[1.625rem] tracking-normal;
  }

  .photon-caption-lg {
    @apply text-[1rem] leading-[1.25rem] tracking-normal;
  }

  .photon-caption-md {
    @apply text-[0.875rem] leading-[1.125rem] tracking-normal;
  }

  .photon-caption-sm {
    @apply text-[0.75rem] leading-[1rem] tracking-normal;
  }

  .photon-caption-xs {
    @apply text-[0.625rem] leading-[1rem] tracking-normal;
  }

  .photon-footer {
    @apply text-[0.625rem] leading-[0.875rem] tracking-normal;
  }

  .photon-label-xs {
    @apply text-[0.625rem] font-[600] leading-[1rem] tracking-normal;
  }

  .photon-label-sm {
    @apply text-[0.875rem] font-[600] leading-[1rem] tracking-normal;
  }

  .photon-label-md {
    @apply text-[1rem] font-[600] leading-[1rem] tracking-normal;
  }

  .photon-label-lg {
    @apply text-[1.125rem] font-[600] leading-[1rem] tracking-normal;
  }

  .homestead-display {
    @apply !font-[dharma-gothic-e] text-[3.5rem] !font-extrabold leading-[56px] tracking-[1.75px];
  }

  .homestead-title-xl {
    @apply !font-[dharma-gothic-e] text-[2.5rem] font-extrabold leading-[40px] tracking-[1.5px];
  }

  .homestead-title-lg {
    @apply !font-[dharma-gothic-e] text-[2rem] font-extrabold leading-[32px] tracking-[1.5px];
  }

  .homestead-title-md {
    @apply !font-[dharma-gothic-e] text-[1.75rem] font-extrabold leading-[28px] tracking-[1.5px];
  }

  .homestead-title-sm {
    @apply !font-[bio-sans] text-[1.313rem] font-semibold leading-[27.3px];
  }

  .homestead-title-xs {
    @apply !font-[bio-sans] text-[1.125rem] font-bold leading-[23.4px];
  }

  .homestead-eyebrow-lg {
    @apply !font-[bio-sans] text-base !font-semibold leading-[16px] tracking-[0.5px];
  }

  .homestead-eyebrow-md {
    @apply !font-[bio-sans] text-sm !font-semibold leading-[14px] tracking-[0.5px];
  }

  .homestead-eyebrow-sm {
    @apply !font-[bio-sans] text-xs !font-semibold leading-[12px] tracking-[0.5px];
  }

  .homestead-eyebrow-xs {
    @apply !font-[bio-sans] text-xs leading-[12px] tracking-[0.5px];
  }

  .homestead-paragraph-lg {
    @apply block !font-[bio-sans] text-[1.313rem] leading-[31.5px];
  }

  .homestead-paragraph-md {
    @apply block !font-[bio-sans] text-[1.125rem] leading-[27px];
  }

  .homestead-paragraph-sm {
    @apply block !font-[bio-sans] text-base leading-[24px];
  }

  .homestead-caption-md {
    @apply !font-[bio-sans] text-base leading-[16px];
  }

  .homestead-caption-sm {
    @apply !font-[bio-sans] text-sm leading-[14px];
  }

  .homestead-tab-text {
    @apply !font-[bio-sans] text-[1.125rem] !font-semibold leading-[18px] tracking-[0.5px];
  }

  .homestead-btn-md {
    @apply !font-[bio-sans] text-[1.125rem] !font-semibold leading-[19.8px];
  }

  .homestead-btn-sm {
    @apply !font-[bio-sans] text-base !font-semibold leading-[19.2px];
  }

  .homestead-btn-xs {
    @apply !font-[bio-sans] text-sm !font-semibold leading-[16.8px];
  }

}
